/* eslint-disable */

<template>
  <div>
    <div class="grid grid-cols-4 gap-4">
      <div class="flex bg-light main-border rounded-lg p-4">
        <div class="flex basis-1/4 items-center">
          <img :src="classTimerToolIcon" width="125" height="125" class="rounded-lg hidden lg:block" alt="" />
        </div>

        <div class="flex flex-col justify-between basis-3/4 ml-4 w-full">
          <div>
            <span class="flex items-center gap-x-1 text-dark">
              <font-awesome-icon icon="fa-solid fa-hourglass-half" class="h-4 w-4" />
              <p>Timer Tool</p>
            </span>
            <h3>Class Timer</h3>
          </div>

          <vs-button
            color="primary"
            icon-pack="feather"
            icon="icon-settings"
            class="mt-2 font-bold px-4 py-2 text-white"
            type="gradient"
            @click="classTimerConfigurator = !classTimerConfigurator"
            >Configure
          </vs-button>
        </div>
      </div>
    </div>

    <vs-popup :active.sync="classTimerConfigurator" title="Class Timer Configurator">
      <div class="p-2 pr-0">
        <h4 class="mb-2">Timer Type</h4>
        <div class="grid grid-cols-3 gap-x-3">
          <vs-radio
            v-model="classType"
            color="white"
            vs-value="Start"
            vs-name="type"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: classType === 'Start' }"
          >
            Class Start
          </vs-radio>

          <vs-radio
            v-model="classType"
            color="white"
            vs-value="Break"
            vs-name="type"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: classType === 'Break' }"
          >
            Class Break
          </vs-radio>

          <vs-radio
            v-model="classType"
            color="white"
            vs-value="Other"
            vs-name="type"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: classType === 'Other' }"
          >
            Other
          </vs-radio>
        </div>

        <div v-if="classType === 'Other'">
          <h4 class="mt-6 mb-2">Timer Title</h4>
          <vs-input v-model="timer.title" name="timer-title" class="w-full" />
        </div>

        <h4 class="mt-6">Timer Duration</h4>
        <div class="grid gap-y-3 gap-x-3 items-center grid-cols-4 mt-2">
          <vs-radio
            v-model="timer.length"
            color="white"
            :vs-value="10"
            vs-name="timer"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: timer.length === 10 && !timer.custom }"
            @input="timer.custom = false"
          >
            10 Minutes
          </vs-radio>

          <vs-radio
            v-model="timer.length"
            color="white"
            :vs-value="15"
            vs-name="timer"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: timer.length === 15 && !timer.custom }"
            @input="timer.custom = false"
          >
            15 Minutes
          </vs-radio>

          <vs-radio
            v-model="timer.length"
            color="white"
            :vs-value="30"
            vs-name="timer"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: timer.length === 30 && !timer.custom }"
            @input="timer.custom = false"
          >
            30 Minutes
          </vs-radio>

          <vs-radio
            v-model="timer.custom"
            color="white"
            :vs-value="true"
            vs-name="timer"
            class="p-3 font-medium rounded-lg timer-option cursor-pointer"
            :class="{ active: timer.custom }"
          >
            Custom
          </vs-radio>
        </div>
        <div class="mt-6" v-if="timer.custom">
          <h4 class="mb-1 mt-3">Enter Custom Time (In Minutes)</h4>
          <div class="flex items-center mb-4">
            <vs-input-number :min="1" :max="720" :max-decimal="0" size="large" v-model="timer.length" />
          </div>
        </div>
      </div>

      <div class="p-2 pr-0 pb-3 flex w-full justify-end">
        <vs-button
          v-if="validConfiguration"
          color="primary"
          icon-pack="feather"
          icon="icon-play"
          class="mt-2 font-bold px-4 py-2 text-white w-full"
          type="gradient"
          @click="startTimer()"
          >Start Timer
        </vs-button>

        <vs-button
          v-if="!validConfiguration"
          color="danger"
          icon-pack="feather"
          icon="icon-alert-triangle"
          class="mt-2 font-bold px-4 py-2 text-white w-full"
          type="gradient"
          disabled
          >Invalid Configuration
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import RadialProgressBar from 'vue-radial-progress';

export default {
  data() {
    return {
      classTimerConfigurator: false,
      validConfiguration: true,
      classType: null,

      timer: {
        custom: false,
        popup: false,
        length: 1,
        display: null,
        title: null,
      },

      classTimerToolIcon: 'https://connect-cdn.intellectualpoint.com/assets/images/icons/instructor-tools/TimerToolIcon.png',
    };
  },
  methods: {
    startTimer() {
      if (this.classType === null || this.timer.length === 1 || (this.classType === 'Other' && this.timer.title === null)) {
        this.validConfiguration = false;
        setTimeout(() => {
          this.validConfiguration = true;
        }, 2000);
      } else {
        this.classTimerConfigurator = false;
        if (!this.classTimerConfigurator) {
          this.$nextTick(() => {
            this.classTimerConfigurator = false;
            this.$router.push({
              path: '/a/instructor-tools/class-timer',
              query: {
                classType: this.classType,
                timer: this.timer,
              },
            });
          });
        }
      }
    },
  },
  components: {
    Prism,
    RadialProgressBar,
  },
  created() {
    this.classTimerConfigurator = false;
  },
  computed: {
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
};
</script>

<style lang="scss">
.timer-option {
  border: 1px solid #181d2a;
  transition: all 0.3s ease-in-out;

  &.active {
    border: 1px solid #0053ff;
    background-color: #020c20;
  }
}

.vs-radio--circle {
  box-shadow: none !important;
}
</style>
